<template>
  <el-tabs v-model="activeName" type="card" style="height: 500px">
    <el-tab-pane label="24小时" name="one">
      <el-table :data="ecDayData" v-infinite-scroll="ecDayData" style="overflow: auto" height="420">
        <el-table-column prop="date" label="时间" width="200">
          <template slot-scope="scope">
            <div>{{ scope.row.date ? scope.row.date + "时" : "" }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="num" label="读数" width="200"></el-table-column>
        <el-table-column prop="differ" label="用量" width="200"></el-table-column>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label="近1月" name="two">
      <el-table :data="ecMonthData" v-infinite-scroll="ecMonthData" style="overflow: auto" height="420">
        <el-table-column prop="time" label="天数" width="200">
          <template slot-scope="scope">
            <div>{{ scope.row.date ? scope.row.date + "号" : "" }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="num" label="读数" width="200"></el-table-column>
        <el-table-column prop="differ" label="用量" width="200"></el-table-column>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label="近12月" name="three">
      <el-table :data="ecYearData" v-infinite-scroll="ecYearData" style="overflow: auto" height="420">
        <el-table-column prop="time" label="月份" width="200">
          <template slot-scope="scope">
            <div>{{ scope.row.date ? scope.row.date + "月" : "" }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="num" label="读数" width="200"></el-table-column>
        <el-table-column prop="differ" label="用量" width="200"></el-table-column>
      </el-table>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
  export default {
    props:["ecDayData","ecMonthData","ecYearData"],
    data() {
      return {
        activeName: "one"
      };
    },
  };
</script>

<style>
</style>